<!--
 * @Description: 采购商详情
 * @Author: zhang zhen
 * @Date: 2023-02-16 13:49:33
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-16 10:35:22
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/supplierInfoForUser.vue
-->
<template>
  <div class="supplierInfo view-content">
    <div class="order-info">
      <a-steps progress-dot :current="orderStatus == -1 ? 9 : orderStatus == -2 ? 7 : orderStatus - 1"
        size="small" v-if="orderProcess == '1'">
        <a-step :title="i" v-for="i in stepsList" :key="i" />
      </a-steps>

      <a-steps progress-dot
        :current="orderStatus == -1 ? 7 : orderStatus == 9 ? 6 : orderStatus >= 3 && orderStatus != 7 ? orderStatus - 1 : orderStatus"
        size="small" v-else>
        <a-step :title="i" v-for="(i, index) in stepsBList" :key="i" />
      </a-steps>
    </div>

    <h2 class="form-title">
      <span class="title">收货/发货信息</span>
    </h2>
    <!--  换成表格展示 -->
    <a-table class="table-box" :columns="columns" :scroll="{ x: 'max-content' }" :pagination="false"
      :data-source="dataSource">
      <template slot="address" slot-scope="text, record, index">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          {{ text | ellipsis(8) }}
        </a-tooltip>
      </template>
      <template slot="order" slot-scope="text, record, index">
        <img :preview="index" src="/order_pic.png" alt="" class="order">
      </template>
      <template slot="shipmentStatus" slot-scope="text, record, index">
        <img src="/to_input.png" alt="" class="to_input" v-if="!record.shipmentStatus || record.shipmentStatus == 1" >
        <img src="/sended.png" alt="" class="to_input"  v-else-if="record.shipmentStatus && record.shipmentStatus == 2 && record.receiveStatus == 1">
        <img src="/last_icon.png" alt="" class="to_input" v-else>
      </template>
      <template slot="action" slot-scope="text, record, index">
        <a-space :size="23">
          <a @click="downloadFile(record)" v-if="record.receiveStatus == 2">下载收货单据</a>
          <a @click="handleViewOrderInfo(record)">查看</a>
        </a-space>
      </template>
    </a-table>
    <!-- <ZPagination style="margin-top: 20px;" :total="pageNation.total" :current="pageNation.pageNum" :pageSize="pageNation.pageSize"
      @handlePageChange="handleChangePage" /> -->
    <h2 class="form-title" style="margin-top: 26px;">
      <span class="title">付款信息</span>
    </h2>
    <div data-v-34c89936="" class="ant-descriptions ant-descriptions-bordered">
      <div class="ant-descriptions-view">
        <table>
          <tbody>
            <tr class="ant-descriptions-row">
              <th class="ant-descriptions-item-label ant-descriptions-item-colon" rowspan="2">包材总额(元)</th>
              <td colspan="1" class="ant-descriptions-item-content" rowspan="2"> {{ payInfo.orderAmount || 0 }} </td>
              <th class="ant-descriptions-item-label ant-descriptions-item-colon">应付金额(元)</th>
              <td colspan="1" class="ant-descriptions-item-content"> {{ payInfo.orderAmount || 0 }} </td>
            </tr>
            <tr class="ant-descriptions-row">
              <!-- <th class="ant-descriptions-item-label ant-descriptions-item-colon">运费金额</th>
              <td colspan="1" class="ant-descriptions-item-content"> ￥0 </td> -->
              <th class="ant-descriptions-item-label ant-descriptions-item-colon">实付金额(元)</th>
              <td colspan="1" class="ant-descriptions-item-content"> {{ payInfo.payAmount || 0 }} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ChangeShipping ref="ChangeShippingRef" @ok="handleLoadInfo" />
    <ShippingInfo ref="ShippingInfoRef" />
  </div>
</template>

<script>
import { getAction, downGetFiles } from '@/api/manage'
import { mapGetters } from 'vuex'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import transportsForm from './transportsForm.vue'
import splitLine from '@/components/plugins/splitLine.vue'
import payVoForm from './payVoForm.vue'
import uploadFile from '@/components/plugins/uploadFileSmall.vue'
import ChangeShipping from './changeShipping.vue'
import ZPagination from '@/components/plugins/ZPagination.vue'
import ShippingInfo from './shippingInfo.vue'
import dayjs from "dayjs";
export default {
  name: 'supplierInfo',
  components: {
    EmptyArea,
    transportsForm,
    payVoForm,
    uploadFile,
    splitLine,
    ChangeShipping,
    ZPagination,
    ShippingInfo
  },
  props: {
    orderNo: {
      type: String,
      default: ''
    },
    needEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      payInfo: {},
      pageNation: {
        total: 0,
        pageSize: 20,
        pageNum: 1
      },
      headerInfo: {},
      orderProcess: '1',
      editTransportFee: false,
      stepsList: ['协议待确定', '待签署', '待预付', '待生产', '生产中', '已发货', '已收货', '已付款', '已完结'],
      stepsBList: ['签合同', '已签署', '生产中', '已发货', '运输中', '已收货', '已收款', '已完结'],
      formItemLayout: {
        labelCol: { span: 11 },
        wrapperCol: { span: 13 }
      },
      loading: false,
      orderProductsVoList: [],
      payVo: {},
      transportsVo: {},
      orderStatus: 0,
      showTransports: false,
      dataSource: [
        
      ],
      total: 0,
      columns: [
        {
          title: '发货单',
          dataIndex: 'shipmentNo',
          width: 100
        },
        {
          title: '收货人',
          dataIndex: 'receiveName',
          width: 100
        },
        {
          title: '收货人电话',
          dataIndex: 'receivePhone',
          width: 160
        },
        {
          title: '收货地址',
          dataIndex: 'address',
          scopedSlots: { customRender: 'address' },
          width: 180
        },
        {
          title: '预计收货时间',
          dataIndex: 'expectedDeliveryDate',
          width: 180
        },
        {
          title: '发货时间',
          dataIndex: 'shipmentTime',
          width: 180
        },
        {
          title: '收货时间',
          dataIndex: 'realReceiveDate',
          width: 180
        },
        {
          title: '发货状态',
          dataIndex: 'shipmentStatus',
          scopedSlots: { customRender: 'shipmentStatus' },
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 180,
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChangePage({ currentPage, pageSize }) {
      this.pageNation.pageNum = currentPage
      this.pageNation.pageSize = pageSize
      // this.handleLoadData()
    },
    handleChangePrice(e, item) {
      if (item.count) {
        item.amount = e * item.count
      }
    },
    handleChangeCount(e, item) {
      if (item.price) {
        item.amount = e * item.price
      }
    },
    handleDeleteItem(index) {
      this.orderProductsVoList.splice(index, 1)
    },
    downloadFile(record) {
      downGetFiles('/order/delivery/download', {
        id: record.id
      }).then((data) => {
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data], { type: 'application/vnd.ms-excel' }), fileName + '.zip')
        } else {
          let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', window.TemplateName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) // 下载完成移除元素
          window.URL.revokeObjectURL(url) // 释放掉blob对象
        }
      })
    },
    handleLoadInfo() {
      getAction('/order/queryByOrderNo', {
        orderNo: this.orderNo,
      }).then(res => {
        const { success, data, message } = res
        if (success) {
          const { status, orderAmount, payAmount } = data
          this.payInfo = {
            orderAmount,
            payAmount
          }
          this.orderStatus = status
        } else {
          this.$message.warning(message)
        }
      })
      getAction('/order/query/delivery', {
        orderNo: this.orderNo,
      }).then(res => {
        const { success, data, message } = res
        if (success) {
          this.dataSource = data && data.map(i => ({
            ...i,
            address: `${i.receiveProvinceName}${i.receiveCityName || ''}${i.receiveAreaName || ''}${i.receiveDetailAddress || ''}`,
            shipmentTime: i.shipmentTime && dayjs(new Date(i.shipmentTime)).format('YYYY-MM-DD') || null,
            realReceiveDate: i.realReceiveDate && dayjs(new Date(i.realReceiveDate)).format('YYYY-MM-DD') || null
          })) || [];
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 运输方式编辑 */
    handleEditTransportInfo() {
      this.$refs.transportsForm.handleEdit({ ...this.transportsVo, objectId: this.orderNo })
    },
    /* 支付信息 */
    handleEditPayVoInfo() {
      this.$refs.payVoForm.handleEdit(this.orderNo)
    },
    /* 修改地址信息 */
    handleChangeOrderAddress(item) {
      this.$refs.ChangeShippingRef.handleEdit(item)
    },
    /* 查看订单信息 */
    handleViewOrderInfo(item) {
      this.$refs.ShippingInfoRef.handleEdit(item)
    },
  }
}
</script>

<style lang="less" scoped>
.supplierInfo {
  background: #fff;

  // border: 1px solid #e4e4e4;
  &-title {
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 15px;
    background: #f3f3f3;
    color: #666;
    font-weight: 500;
  }

  &-orderInfo {
    background: #fff;
    padding: 30px 25px;
    border-bottom: 1px solid #e4e4e4;

    .orderNum {
      font-size: 16px;
      color: #999;
      font-weight: 500;
    }

    &-content {
      margin-top: 15px;
      .flexLayout();
    }

    &-left {
      .flexLayout(@direction: column; );
      border-right: 1px solid #e6e6e6;
      padding: 10px 30px 10px 0;

      .label {
        margin-bottom: 8px;
        color: #e12936;
        font-size: 22px;
        font-weight: 500;
      }

      .ant-tag {
        width: 78px;
        text-align: center;
        margin-right: 0;
      }
    }

    &-right {
      flex: 1 0 0;
      .flexLayout(@justifyContent: flex-start; );
      padding: 0 20px;

      .status-step {
        flex: 1;
        .flexLayout(@direction: column; @alignItem: flex-start);
        overflow: hidden;
        position: relative;
        margin-right: 16px;

        .anticon {
          margin-bottom: 8px;
          font-size: 30px;
          margin-left: 8px;
        }

        .label {
          font-size: 16px;
          color: #999;
          font-weight: 500;
        }

        &::after {
          position: absolute;
          top: 16px;
          left: 52px;
          display: block;
          width: 999px;
          height: 1px;
          border-top: 1px dashed #797979;
          content: '';
        }

        &:last-of-type {
          flex: none;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  &-userInfo {
    background: #fff;
    padding: 30px;
    border-bottom: 1px solid #e4e4e4;
    position: relative;
    .flexLayout(@justifyContent: flex-start; @alignItem: flex-start);

    .action_btn {
      position: absolute;
      right: 30px;
      top: 30px;
    }

    .leftBox {
      width: 240px;
      border-right: 1px solid #e6e6e6;
      padding-right: 40px;
      padding-bottom: 20px;
    }

    .title {
      font-size: 16px;
      color: #666;
      font-weight: 500;
    }

    .rightBox {
      flex: 1 0 0;
      padding-left: 40px;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .orderInfo-table {

    &-title,
    &-row {
      .flexLayout();
      text-align: center;
      padding: 20px 15px;
      color: #666;
      position: relative;

      .buttonArea {
        position: absolute;
        bottom: 20px;
        right: 15px;
      }

      .productInfo {
        width: 26%;
      }

      .price {
        width: 16%;
      }

      .subCount {
        width: 20%;
      }

      .payMoney {
        width: 20%;
      }
    }

    &-title {
      background: #eeeeee;
    }

    &-row {
      color: #333;
      border-bottom: 1px solid #e4e4e4;

      .productInfo {
        .flexLayout(@justifyContent: flex-start; @alignItem: flex-start);
        padding-left: 20px;

        img {
          width: 75px;
          height: 75px;
        }

        .info {
          padding-left: 15px;
          .flexLayout(@direction: column; @alignItem: flex-start);

          .subTitle {
            font-size: 16px;
            margin-bottom: 10px;
          }

          .size {
            color: #999;
          }
        }
      }
    }

    .red {
      color: #e12936;
    }

    &-subNum {
      padding: 20px 60px;
      background: #fff;

      ::v-deep .ant-form-item-control {
        text-align: right;
      }

      .lastItem {
        margin-top: 60px;

        ::v-deep .ant-form-item-label label {
          color: #e12936;
        }

        ::v-deep .ant-form-item-children {
          font-size: 22px;
          color: #e12936;
        }
      }
    }
  }
}

.form-title {
  font-size: 15px;
  color: #605f5f;
  margin-bottom: 15px;
  height: 38px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  position: relative;

  &:before {
    background: #FF6E2D;
    display: inline-block;
    content: '';
    width: 5px;
    height: 16px;
    margin-right: 8px;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-right: 10px;
  }

  .action {
    position: absolute;
    right: 0;
    top: 50%;
    font-size: 14px;
    transform: translateY(-50%);

    img {
      width: 15px;
      margin-right: 4px;
    }
  }
}

.order-info {
  padding: 24px 0;

  &-top {
    padding-bottom: 16px;
    border-bottom: 1px solid #e4e4e5;
    margin-bottom: 25px;

    .title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      font-weight: 500;
      margin-right: 8px;
    }

    .tag {
      width: 52px;
      height: 21px;
      display: inline-block;
      line-height: 21px;
      background: rgba(5, 194, 156, 0.1);
      border-radius: 4px;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #05c29c;
    }
  }
}

.ant-descriptions {
  margin-top: 25px;
  margin-bottom: 32px;

  ::v-deep .ant-descriptions-item-label {
    color: rgba(0, 0, 0, 0.65);
  }

  ::v-deep .ant-descriptions-item-content {
    color: rgba(0, 0, 0, 0.85);
  }
}

::v-deep .ant-table-thead>tr>th {
  background: #efefef;
  border-bottom: none;
}

::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

.info span {
  color: rgba(0, 0, 0, 0.85);
}

.productImg {
  width: 60px;
  height: 45px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 8px;
}

.info-table {
  display: flex;
  align-items: center;

  ::v-deep.ant-input {
    height: 38px;
    margin-left: 8px;
  }
}

.add-btn {
  border-color: #d9d9d9;
  color: #595959;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  margin-bottom: 24px;
}

.sum-info {
  width: 100%;
  height: 183px;
  padding: 24px 32px;
  background: #fff5ee;
  border: 1px solid #ef8150;
  border-radius: 8px;

  &-top {
    border-bottom: 1px solid #E4E4E6;
    margin-bottom: 16px;

    &-item {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #595959;
      padding-bottom: 17px;

      .price {
        color: #FF6E2D;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  &-price {
    text-align: right;
    font-weight: 500;
    font-size: 16px;
    color: #131212;

    .price-num {
      color: #FF6E2D;
      font-size: 18px;
    }
  }
}

::v-deep .table-box {
  border-left: 1px solid #E8E9EB;
  border-right: 1px solid #E8E9EB;
  border-top: 1px solid #E8E9EB;
}

::v-deep .ant-table-thead>tr>th {
  background: #F4F5F7;
  padding: 14px 16px;
  font-size: 14px;
}

::v-deep .ant-table-tbody tr>td {
  padding: 9px 16px;
  font-size: 14px;
}

::v-deep .ant-table-row {
  height: 60px !important;
}

img.to_input {
  width: 52px;
}

img.order {
  width: 60px;
  border-radius: 4px;
}

@import '~@/styles/form.less';
@import '~@/styles/orderDetail.less';
::v-deep .ant-select-sm .ant-select-selection--single {
  height: 24px!important;
}

::v-deep .ant-select-selection__rendered  {
  line-height: 22px!important;
}

::v-deep .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot, ::v-deep .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #21D95E;
}

::v-deep .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 10px;
  height: 10px;
  line-height: 10px
}

::v-deep .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background: #21D95E;
}
</style>
