<!--
 * @Description: 发货单信息
 * @Author: zhang zhen
 * @Date: 2023-05-30 11:00:59
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-16 11:47:23
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/children/shippingInfo.vue
-->
<template>
  <DrawerView :visible="visible" :width="960" title="发货单信息" @confirm="handleOk" @cancel="handleCancel">
    <a-form-model
      class="info-form"
      :label-col="{
        span: 6,
      }"
      :wrapper-col="{
        span: 18,
      }"
      :colon="false"
    >
      <h2 class="page-title">
        <span class="title">发货信息</span>
      </h2>
      <a-row class="formInfo">
        <a-col :span="12">
          <a-form-model-item label="发货方式" prop="shipmentType">
            <j-dictSelect
              v-model="form.shipmentType"
              style="width: 100%"
              placeholder="请选择"
              dictCode="1018"
              disabled
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="运单号" prop="shipmentNo" v-if="form.shipmentType != '1018002'">
            <a-input placeholder="请输入" v-model="form.shipmentNo" style="width: 100%" disabled></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="发货时间" prop="deliveryDate">
            <JDate
              placeholder="请选择"
              style="width: 100%"
              v-model="form.shipmentTime"
              :removePast="true"
              :disabled="true"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <h2 class="page-title">
        <span class="title">收货信息</span>
      </h2>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="期望收货时间">
            <j-date placeholder="请选择" v-model="form.expectedDeliveryDate" style="width: 100%" disabled />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="收货人">
            <a-input placeholder="请输入" v-model="form.receiveName" style="width: 100%" disabled></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="手机号">
            <a-input placeholder="请输入" v-model="form.receivePhone" style="width: 100%" disabled></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item
            label="收货地址"
            :label-col="{
              span: 3,
            }"
            :wrapper-col="{
              span: 21,
            }"
          >
            <a-input
              placeholder="请输入"
              class="lastBtn"
              v-model="form.receiveDetailAddress"
              style="width: 100%"
              :maxLength="20"
              disabled
            >
              <div class="NumberInfo" slot="suffix">
                {{ (form.receiveDetailAddress && form.receiveDetailAddress.length) || 0 }}/20
              </div>
            </a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <!-- 收货信息 -->
    <h2 class="page-title">
      <span class="title">收货数量</span>
    </h2>
    <a-table class="table-box" :columns="columns" :pagination="false" :data-source="dataSource" bordered></a-table>
    <h2 class="page-title">
      <span class="title">收货单据</span>
    </h2>
    <!-- 预览单据图片 -->
    <div class="order-pic" v-if="form.receiveFile">
      <img preview="1" :src="form.receiveFile" alt="" class="cover" v-if="form.receiveFile.IsPicture()" />
      <img src="/fileView.png" alt="" class="cover file" v-else @click="handleViewFile" />
      <!-- fileView -->
      <a @click="handleDownLoad">下载</a>
    </div>
    <a-empty v-else description="暂无收货单据"></a-empty>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65)">取消</a-button>
    </template>
  </DrawerView>
</template>

<script>
import { getAction, postAction, downGetFiles } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
import UploadDrag from '@/components/plugins/uploadDrag'
import JDate from '@/components/easyComponents/JDate.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
// String 构造函数的原型对象的一个方法。
String.prototype.IsPicture = function () {
  //判断是否是图片 - strFilter必须是小写列举
  var strFilter = '.jpeg|.jpg|.png|.pic|'
  if (this.indexOf('.') > -1) {
    var p = this.lastIndexOf('.')
    //alert(p);
    //alert(this.length);
    var strPostfix = this.substring(p, this.length) + '|'
    strPostfix = strPostfix.toLowerCase()
    //alert(strPostfix);
    if (strFilter.indexOf(strPostfix) > -1) {
      //alert("True");
      return true
    }
  }
  //alert('False');
  return false
}

function downloadImage(url) {
  let name = url.split('/images/')[1];
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('Failed to download image.'));
}
export default {
  name: 'transportsForm',
  components: {
    UploadDrag,
    JDate,
    DrawerView,
    JDictSelect,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        detailAddress: '',
      },
      regionOptions: [],
      regionList: [],
      deliveryCityList: [],
      deliveryDistrictList: [],
      uploadFiles: [],
      lastDate: null,
      orderNo: null,
      columns: [
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          width: 173,
        },
        {
          title: '散件SKU名称',
          dataIndex: 'skuName',
          width: 163,
        },
        {
          title: '计划所需数量(个)',
          dataIndex: 'skuPlanQuantity',
          width: 183,
        },
        {
          title: '实际发货数量(个)',
          dataIndex: 'shipmentQuantity',
          width: 183,
        },
        {
          title: '实际收货数量(个)',
          dataIndex: 'receiveQuantity',
          width: 183,
        },
      ],
      dataSource: [],
    }
  },
  methods: {
    handleEdit(item) {
      this.loadRegionList()
      this.visible = true
      this.form = { ...item }
      this.dataSource = item.shipmentItems || []
    },
    handleChangeFile(e) {
      this.uploadFiles = e.splice(-1, 1)
    },
    handleViewFile() {
      window.open(this.form.receiveFile)
    },
    handleDownLoad() {
      downloadImage(this.form.receiveFile)
    },
    changeInfo(e) {
      this.form.deliveryAddress = e
      this.handleUpdateView()
      this.handleChangeRootId()
    },
    handleOk() {
      this.$refs['infoForm'].validate((valid) => {
        if (valid) {
          this.confirmLoading = true
          postAction('/order/transport/updateTransport', {
            ...this.form,
          })
            .then((res) => {
              const { success, message } = res
              this.confirmLoading = false
              if (success) {
                this.handleCancel()
                this.$emit('ok')
              } else {
                this.$message.warning(message)
              }
            })
            .catch((e) => {
              this.confirmLoading = false
            })
        }
      })
    },
    loadRegionList() {
      getAction('/area/queryByLevel', {
        level: 1,
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this.regionList = data
        }
      })
    },
    /* 获取用户信息更新 */
    handleLoadUserInfo(callback) {
      let storageInfo = localStorage.getItem('authInfo')
      const { userId } = JSON.parse(storageInfo)
      getAction('/user/info', { userId }).then((res) => {
        const { success, data } = res
        if (success) {
          let userInfo = JSON.stringify(data)
          localStorage.setItem('userInfo', userInfo)
          this.$store.commit('setUserInfo', data)
          callback()
        }
      })
    },
    handleChangeRootId(flag = false) {
      this.deliveryCityList = []
      if (!flag) {
        this.deliveryDistrictList = []
        this.form.deliveryCity = undefined
        this.form.deliveryDistrict = undefined
      }
      this.handleLoadCity('deliveryCity', flag)
    },
    handleUpdateView() {
      this.$forceUpdate()
    },
    handleLoadCity(key, flag = false) {
      let parentId = ''
      if (key == 'deliveryCity') {
        this.deliveryCityList = []
        this.deliveryDistrictList = []
        !flag && (this.form.deliveryCity = undefined)
        parentId = this.form.deliveryAddress
      }
      if (key == 'deliveryDistrict') {
        this.deliveryDistrictList = []
        !flag && (this.form.deliveryDistrict = undefined)
        parentId = this.form.deliveryCity
      }
      getAction('/area/queryByParentIdList', {
        parentId,
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this[`${key}List`] = data
        }
      })
    },
    handleCancel() {
      this.form = {
        detailAddress: '',
      }
      this.lastDate = null
      this.visible = false
      this.confirmLoading = false
      this.uploadFiles = []
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/form.less';

.page-title {
  width: 100%;

  &:not(:first-of-type) {
    margin-top: 28px;
  }

  &:first-of-type {
    margin-top: -8px;
  }

  &.not-first {
    margin-top: 0;
  }

  font-size: 15px;
  color: #605f5f;
  margin-top: 8px;
  margin-bottom: 24px;
  height: 38px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  position: relative;

  &:before {
    background: #ff6e2d;
    display: inline-block;
    content: '';
    width: 5px;
    height: 16px;
    margin-right: 8px;
  }

  .title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-right: 10px;
  }

  .action {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    color: #ff6026;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;

    img.icon {
      width: 16px;
      margin-right: 4px;
    }
  }
}

.table-box {
  min-height: auto !important;

  ::v-deep .ant-table-tbody tr:not(:last-of-type) > td {
    border-right: 1px solid #e8e9eb !important;
  }

  ::v-deep .ant-table-thead > tr > th {
    background: #f4f5f7;
    border-right: 1px solid #e8e8e8 !important;
    border-bottom: 1px solid #e8e8e8 !important;
    padding: 8px 10px;
    font-size: 14px;
  }

  ::v-deep .ant-table-column-title {
    font-size: 14px;
  }

  ::v-deep .ant-table-tbody tr > td {
    border-bottom: 1px solid #e8e9eb !important;
    padding: 5px 10px;
  }

  ::v-deep .ant-table-wrapper {
    min-height: auto !important;
    border-bottom: none;
  }

  ::v-deep .ant-table-row {
    height: 38px !important;
  }
}

.order-pic {
  width: 300px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 35px;
  img.cover {
    width: 240px;
    height: 164px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    &.file {
      width: auto;
      border: 1px solid #e8e9eb;
      cursor: pointer;
    }
  }
}
.formInfo {
  ::v-deep .ant-select-selection__rendered {
    line-height: 38px !important;
  }
}
</style>
